<!-- 管理首页-个人设置 -->
<template>
    <page-layout class="personal-setting-page">
        <div class="personal-setting-page__wrapper">
            <a-menu
                mode="inline"
                theme="light"
                :default-selected-keys="defaultSelectedKeys"
                class="personal-setting-page__menu"
                @click="handleClickMenu"
            >
                <template v-for="item in menuList">
                    <a-menu-item :key="item.key">{{ item.label }}</a-menu-item>
                </template>
            </a-menu>
            <div class="personal-setting-page__content">
                <div class="personal-setting-page__title">{{ title }}</div>
                <slot />
            </div>
        </div>
    </page-layout>
</template>

<script>
import { Menu as AMenu } from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'

export default {
    name: 'SysHomePage',
    components: {
        AMenu,
        AMenuItem: AMenu.Item,
        PageLayout
    },
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            menuList: [
                {
                    key: 'BasicSetting',
                    label: '基本设置'
                }
                // {
                //   key: "SafetySetting",
                //   label: "安全设置"
                // }
            ],
            defaultSelectedKeys: ['BasicSetting']
        }
    },
    watch: {
        $route: {
            handler(route) {
                const { name } = route
                this.defaultSelectedKeys = [name]
            },
            immediate: true
        }
    },
    methods: {
        handleClickMenu(e) {
            const { key } = e
            this.$router.push({
                name: key
            })
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';
.personal-setting-page {
  &__wrapper {
    background: #fff;
    display: flex;
    padding-top: 20px;
  }
  &__menu {
    width: 190px;
    min-height: 410px;
  }
  &__content {
    flex: 1;
    padding: 0 40px;
  }
  &__title {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: bold;
    color: #0f0f0f;
    margin-bottom: 15px;
    margin-top: 10px;
  }
}
</style>
