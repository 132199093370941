<!-- 基本信息 -->
<template>
    <div>
        <!-- 基本信息 -->
        <basic-information-panel
            :info="info" />
        <!-- 基本信息 -->
        <quota-information-panel
            :info="info" />
    </div>
</template>

<script>
import BasicInformationPanel from './BasicInformationPanel'
import QuotaInformationPanel from './QuotaInformationPanel'

export default {
    name: 'EnterpriseBasicInformation',
    components: {
        BasicInformationPanel,
        QuotaInformationPanel
    },
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {},
    methods: {}
}
</script>

<style lang="scss">
.enterprise-basic-information {
  // margin-top: -20px;
}
</style>
