<template>
    <a-form-model
        ref="ruleForm"
        class="add-edit-category-form-panel"
        layout="horizontal"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
        :model="form"
        :rules="rules">
        <a-form-model-item label="产品分类">
            {{ parentInfo.name }}-{{ categoryInfo.name }}
        </a-form-model-item>
        <a-form-model-item label="关联参数" prop="productSpecId">
            <select-option-label-prop
                v-model="form.productSpecId"
                ref="selectOption"
                :select-options-data="dataList"
                :filter-option="false"
                placeholder="请选择参数">
                <a-pagination
                    v-model="current"
                    slot="footer"
                    class="select-option-label-prop_pagination"
                    :show-total="total => `总共${total}条`"
                    :total="total"
                    show-less-items
                    @change="handleChange" />
            </select-option-label-prop>
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel,
    Pagination as APagination
} from 'ant-design-vue'
import SelectOptionLabelProp from '@weights/SelectOptionLabelProp'

import { ERROR_COMMIT_SUCCESS } from '@constant/error'

import addRiskCategoryProspecService from '@service/commodityManage/productCategory/addRiskCategoryProspecService'
import getOpenParamsListService from '@service/commodityManage/paramsList/getOpenParamsListService'
import getParamsByCategoryService from '@service/commodityManage/productCategory/getParamsByCategoryService'
import { DEFAULT_PAGES_SIZE } from '@config'

export default {
    name: 'FormPanel',
    components: {
        SelectOptionLabelProp,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        APagination
    },
    props: {
        parentInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        categoryInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        mode: {
            type: String,
            default: 'create'
        }
    },
    data() {
        return {
            value: ['china'],
            form: {
                productSpecId: []
            },
            rules: {
                productSpecId: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择关联参数'
                    }
                ]
            },
            total: 0,
            current: 1,
            limit: DEFAULT_PAGES_SIZE,
            dataList: []
        }
    },
    computed: {
        parentId() {
            return this.parentInfo.id || ''
        }
    },
    watch: {},
    created() {
        this.getInitParamsList()
        this.getList()
    },
    methods: {
        async handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService()
                } else {
                    return false
                }
            })
        },
        async submitService() {
            this.form.id = this.categoryInfo.id
            try {
                this.$emit('loading', true)
                await addRiskCategoryProspecService(this.form)
                this.$message.success(ERROR_COMMIT_SUCCESS)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.$emit('loading', false)
            }
        },
        handleChange(val) {
            this.current = val
            this.getList()
        },
        async getList() {
            try {
                const { current, limit } = this
                let param = {
                    current,
                    size: limit
                }
                const result = await getOpenParamsListService(param)

                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        async getInitParamsList() {
            try {
                const riskCategoryId = this.categoryInfo.id
                const initData = await getParamsByCategoryService({
                    riskCategoryId
                })
                initData.forEach(item => {
                    this.form.productSpecId.push(item.productSpecId)
                })
            } catch (e) {
                this.$message.error(e.message)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
