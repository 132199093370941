<!-- 企业管理-企业列表 -->
<template>
    <list-page-layout>
        <search-panel slot="header" @search="handleSearch" @refresh="handleRefresh" />
        <container-panel>
            <template slot="title">企业列表</template>
            <!--      <a-button
        slot="button"
        type="primary"
        v-auth="ENTERPRISE_MANAGE_ENTERPRISE_LIST_EXPORT_ENTERPRISE_BUTTON"
        @click="handleExport"
      >导出</a-button> -->
            <template slot="content">
                <a-table
                    :columns="columns"
                    :data-source="dataList"
                    :pagination="pagination"
                    :loading="loading"
                    :scroll="scrollConfig"
                    row-key="id"
                    @change="handleTableChange"
                >
                    <template slot="action" slot-scope="action">
                        <a-button
                            v-auth="ENTERPRISE_MANAGE_ENTERPRISE_LIST_LOOK_ENTERPRISE_BUTTON"
                            type="link"
                            @click="handleDetail(action)"
                        >
                            查看
                        </a-button>
                    </template>
                </a-table>
            </template>
        </container-panel>
    </list-page-layout>
</template>
<script>
import { Button as AButton, Table as ATable } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import SearchPanel from './SearchPanel'

import {
    ENTERPRISE_MANAGE_ENTERPRISE_LIST_LOOK_ENTERPRISE_BUTTON,
    ENTERPRISE_MANAGE_ENTERPRISE_LIST_EXPORT_ENTERPRISE_BUTTON
} from '@constant/authEnum/enterprise'
import { DEFAULT_PAGES_SIZE } from '@config'

import tableShowTotal from '@mixins/tableShowTotal'

import getEnterpriseListService from '@service/enterprise/getEnterpriseListService'
import { open } from '@utils'

const columns = [
    {
        title: '序号',
        dataIndex: 'sort',
        align: 'left',
        key: 'sort',
        fixed: 'left',
        width: 100,
        customRender: (v, r, idx) => ++idx
    },
    {
        title: '企业名称',
        dataIndex: 'name',
        align: 'left',
        key: 'name',
        width: 250
    },
    {
        title: '统一社会信用代码',
        dataIndex: 'socialCreditCode',
        align: 'left',
        key: 'socialCreditCode',
        width: 200
    },
    {
        title: '订单数量',
        dataIndex: 'orderQuantity',
        align: 'left',
        key: 'orderQuantity',
        width: 120
    },
    {
        title: '最近下单时间',
        dataIndex: 'lastOrderTime',
        align: 'left',
        key: 'lastOrderTime',
        width: 200
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        scopedSlots: {
            customRender: 'action'
        },
        width: 150,
        fixed: 'right'
    }
]
export default {
    name: 'EnterpriseList',
    components: {
        SearchPanel,
        ListPageLayout,
        ContainerPanel,
        AButton,
        ATable
    },
    mixins: [tableShowTotal],
    data() {
        return {
            ENTERPRISE_MANAGE_ENTERPRISE_LIST_LOOK_ENTERPRISE_BUTTON, // 查看
            ENTERPRISE_MANAGE_ENTERPRISE_LIST_EXPORT_ENTERPRISE_BUTTON, // 导出
            columns,
            dataList: [
                {
                    id: 1
                }
            ],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            visible: false,
            scrollConfig: {
                x: 1100,
                y: 500
            }
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        handleRefresh(params) {
            this.handleSearch(params)
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params } = this
                const param = {
                    current,
                    size: limit,
                    ...params
                }
                const result = await getEnterpriseListService(param)
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        handleExport() {
        },
        handleDetail({ id }) {
            open.call(this, 'EnterpriseListInfo', {
                id
            })
        }
    }
}
</script>
<style lang="scss">
</style>
