<!-- 管理首页-消息通知 -->
<template>
    <list-page-layout class="message-center-page">
        <search-panel slot="header" @search="handleSearch" @refresh="handleRefresh" />
        <container-panel>
            <custom-button
                slot="button"
                v-auth="HOME_PAGE_MESSAGE_CENTER_ALL_READED_BUTTON"
                text="一键已读"
                @click="handleAllReaded"
            />
            <template slot="content">
                <a-table
                    :columns="columns"
                    :data-source="dataList"
                    :pagination="pagination"
                    :loading="loading"
                    :scroll="scrollConfig"
                    row-key="id"
                    :row-selection="{
                        selectedRowKeys: selectedRowKeys,
                        onChange: onSelectChange ,
                        getCheckboxProps: record => ({
                            props: {
                                disabled: isRead(record.state)
                            },
                        }),}"
                    @change="handleTableChange">
                    <template slot="content" slot-scope="text,action">
                        <span :class="{noAllowed:isRead(action.state),pointer:!isRead(action.state)}"
                              @click="handleContent(action)">{{ text }}</span>
                    </template>
                    <template slot="action" slot-scope="text,action">
                        <a-button
                            v-auth="HOME_PAGE_MESSAGE_CENTER_SET_READED_BUTTON"
                            type="link"
                            :disabled="action.state===IS_READE"
                            @click="handleReaded(action.id)"
                        >
                            设为已读
                        </a-button>
                    </template>
                </a-table>
            </template>
        </container-panel>
    </list-page-layout>
</template>

<script>
import { Button as AButton, Table as ATable } from 'ant-design-vue'
import CustomButton from '@components/Button'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import SearchPanel from './SearchPanel'

import {
    HOME_PAGE_MESSAGE_CENTER_ALL_READED_BUTTON,
    HOME_PAGE_MESSAGE_CENTER_SET_READED_BUTTON
} from '@constant/authEnum/homePage'
import { DEFAULT_PAGES_SIZE } from '@config'
import { IS_READE } from '@constant/enum'
import { isRiskProcurement, isPushOrderInfo } from '@constant/function'
import { ERROR_COMMIT_SETTING } from '@constant/error'

import tableShowTotal from '@mixins/tableShowTotal'
import getMessageListService from '@service/home/getMessageListService'
import batchOneMessageService from '@service/home/batchOneMessageService'
import batchAllMessageService from '@service/home/batchAllMessageService'

const columns = [
    {
        title: '消息内容',
        dataIndex: 'content',
        align: 'left',
        key: 'content',
        scopedSlots: {
            customRender: 'content'
        },
        width: 450
    },
    {
        title: '提醒时间',
        dataIndex: 'sendingTime',
        align: 'left',
        key: 'sendingTime',
        width: 200
    },
    {
        title: '消息类型',
        dataIndex: 'messageTypeDesc',
        align: 'left',
        key: 'messageTypeDesc',
        width: 200
    },
    {
        title: '消息状态',
        dataIndex: 'stateDesc',
        align: 'left',
        key: 'stateDesc',
        width: 150
    },
    {
        title: '操作',
        dataIndex: 'action',
        align: 'left',
        key: 'action',
        scopedSlots: {
            customRender: 'action'
        },
        width: 150,
        fixed: 'right'
    }
]

export default {
    name: 'MessageCenter',
    components: {
        AButton,
        ATable,
        ListPageLayout,
        ContainerPanel,
        SearchPanel,
        CustomButton
    },
    mixins: [tableShowTotal],
    data() {
        return {
            IS_READE,
            HOME_PAGE_MESSAGE_CENTER_ALL_READED_BUTTON,
            HOME_PAGE_MESSAGE_CENTER_SET_READED_BUTTON,
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            visible: false,
            scrollConfig: {
                x: 1100,
                y: 500
            },
            dataList: [],
            columns,
            selectedRowKeys: []
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        rowSelection() {
            return {
                getCheckboxProps: record => ({
                    props: {
                        disabled: this.record(record.state)
                    }
                })
            }
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        isRead(state) {
            return state === IS_READE
        },
        getPushName(messageType) {
            let name = ''
            if (isRiskProcurement(messageType)) { // 跳转风控订单详情页
                name = 'OrderInfo'
            } else if (isPushOrderInfo(messageType)) { // 跳订单详情页
                name = 'OrderListInfo'
            } else {
                name = 'TaskInfo' // 跳转任务详情页
            }
            return name
        },
        handleContent({ content, id, state, messageType }) {
            if (this.isRead(state)) return// 已读不跳转
            const riskControlNumber = content.match(/GBJZ[-\d]+/)[0]
            const name = this.getPushName(messageType)
            this.$router.push({
                name,
                query: {
                    riskControlNumber
                }
            })
            this.handleReaded(id, false)
        },
        /**
         * 一键已读
         */
        handleAllReaded() {
            const { selectedRowKeys } = this
            if (selectedRowKeys.length) {
                this.handleReadedAll(selectedRowKeys.join(','))
            } else {
                this.$message.warning('请选中要设为已读的消息')
            }
        },
        /**
         * 设为已读
         */
        async handleReaded(id, showToast = true) {
            try {
                await batchOneMessageService(id)
                this.$store.dispatch('getMessageNum')// 重新获取未读数
                this.getList()
                if (!showToast) return// 点击跳转任务详情页不显示提示信息
                this.selectedRowKeys = []
                this.$message.success(ERROR_COMMIT_SETTING)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        async handleReadedAll(ids) {
            try {
                await batchAllMessageService({
                    ids
                })
                this.getList()
                this.selectedRowKeys = []
                this.$message.success(ERROR_COMMIT_SETTING)
                this.$store.dispatch('getMessageNum')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        handleRefresh(params) {
            this.handleSearch(params)
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params } = this
                const param = {
                    current,
                    size: limit,
                    ...params
                }
                const result = await getMessageListService(param)
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.pointer {
    cursor: pointer;
}

.noAllowed {
    cursor: not-allowed
}
</style>
