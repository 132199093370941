<template>
    <search-panel
        :model="form"
        layout="vertical"
        class="enterprise-list-search-panel"
        @search="handleSearch"
        @refresh="handleRefresh"
    >
        <template slot="title">筛选查询</template>
        <form-col :span="6"
                  :label-span="4"
                  :wrapper-span="16"
                  label="消息类型"
                  prop="messageType">
            <template slot="formElements">
                <a-select v-model="form.messageType" placeholder="请选择消息类型">
                    <a-select-option
                        v-for="item in MESSAGE_TYPE_ENUM_LIST"
                        :key="item.value"
                        :value="item.value">
                        {{ item.label }}
                    </a-select-option>
                </a-select>
            </template>
        </form-col>
        <form-col :span="6"
                  :label-span="4"
                  :wrapper-span="16"
                  label="提醒时间"
                  prop="sendingTime">
            <submit-time-section
                v-model="form.sendingTime"
                slot="formElements"
                @time="handleTime" />
        </form-col>
    </search-panel>
</template>

<script>
import { Select as ASelect } from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'
import { MESSAGE_TYPE_ENUM_LIST } from '@constant/enum'
import { filterParams } from '@utils/search.js'

export default {
    name: 'MessageCenterSearchpanel',
    components: {
        SubmitTimeSection: () => import('@weights/SubmitTimeSection'),
        SearchPanel,
        FormCol,
        ASelect,
        ASelectOption: ASelect.Option
    },
    data() {
        return {
            MESSAGE_TYPE_ENUM_LIST,
            form: {
                messageType: undefined,
                sendingTime: [],
                sendingTimeStart: '',
                sendingTimeEnd: ''
            },
            timeMode: ['date', 'date']
        }
    },
    computed: {
        params() {
            const { form } = this
            let params = JSON.parse(JSON.stringify(form))
            delete params.sendingTime
            return params
        }
    },
    methods: {
        handleTime(val) {
            this.form.sendingTimeStart = val[0] + ' 00:00:00'
            this.form.sendingTimeEnd = val[1] + ' 00:00:00'
        },
        handleSearch() {
            const { params } = this
            this.$emit('search', filterParams(params))
        },
        handleRefresh() {
            this.form.sendingTimeStart = ''
            this.form.sendingTimeEnd = ''
            const { params } = this
            this.$emit('refresh', filterParams(params))
        }
    }
}
</script>

<style lang="scss">
</style>
