<!-- 额度信息 -->
<template>
    <container-panel class="enterprise-quota-information-page-panel" :no-card-body-padding-top="true">
        <div slot="title">额度信息</div>
        <quota-information-panel :info="info" />
    </container-panel>
</template>

<script>
import ContainerPanel from '@components/ContainerPanel'
import QuotaInformationPanel from '@weights/EnterpriseInfo/EnterpriseInfoPanel/BasicInformation/QuotaInformationPanel'

export default {
    name: 'EnterpriseQuotaInformationPagePanel',
    components: {
        ContainerPanel,
        QuotaInformationPanel
    },
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {},
    methods: {}
}
</script>

<style lang="scss">
.enterprise-quota-information-page-panel {
  // margin-top: -20px;
}
</style>
