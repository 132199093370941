<!-- 企业详情-征信报告-查看征信报告 -->
<template>
    <credit-report />
</template>

<script>
export default {
    name: 'CreditReportDetailPagePanel',
    components: {
        CreditReport: () => import('@weights/CreditReport')
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss">
</style>
