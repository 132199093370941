export const tabsTwoData = [
    {
        key: 'OrderDetailInfoPanel',
        tab: '详细信息'
    }, {
        key: 'InsureInfoPanel',
        tab: '承保信息'
    }
]
export const tabsThreeData = [
    {
        key: 'OrderDetailInfoPanel',
        tab: '详细信息'
    },
    {
        key: 'RelevanceInfoPanel',
        tab: '关联单'
    },
    {
        key: 'InsureInfoPanel',
        tab: '承保信息'
    },
    {
        key: 'OperationLogInfoPanel',
        tab: '操作日志'
    }
]
export const tabsFourData = [
    {
        key: 'OrderDetailInfoPanel',
        tab: '详细信息'
    },
    {
        key: 'RiskControlInfoPanel',
        tab: '风控信息'
    },

    {
        key: 'RelevanceInfoPanel',
        tab: '关联单'
    },
    {
        key: 'InsureInfoPanel',
        tab: '承保信息'
    },
    {
        key: 'OperationLogInfoPanel',
        tab: '操作日志'
    }
]
export const tabsFiveData = [
    {
        key: 'OrderDetailInfoPanel',
        tab: '详细信息'
    },
    {
        key: 'RiskControlInfoPanel',
        tab: '风控信息',
        bidType: false// 投标商品不显示
    },
    {
        key: 'RiskAccessInfoPanel',
        tab: '风险评估',
        bidType: false
    },
    {
        key: 'RelevanceInfoPanel',
        tab: '关联单'
    },
    {
        key: 'InsureInfoPanel',
        tab: '承保信息'
    },
    {
        key: 'OperationLogInfoPanel',
        tab: '操作日志'
    }
]
