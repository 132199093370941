<!-- 企业管理-企业详情 -->
<template>
    <page-layout class="enterprise-info-page">
        <container-panel class="enterprise-info-page-panel">
            <div slot="title" class="enterprise-info-page__title">
                <a-avatar class="avatar" size="small" shape="circle" :src="avatar" />
                <span class="enterprise-info-page__title-text">{{ info.name }}</span>
            </div>
            <!--      <a-button type="primary" slot="button">下载报告</a-button> -->
            <div class="enterprise-info-page__info">
                <div class="enterprise-info-page__info-panel">
                    <a-descriptions>
                        <a-descriptions-item label="创建时间">{{ info.createDateTime }}</a-descriptions-item>
                    </a-descriptions>
                </div>
            </div>
            <tabs-panel
                :tabs-data="tabsData"
                :default-active-key="defaultActiveKey"
                @handle-tabs-change="handleTabsChange"
            />
        </container-panel>
        <component
            :is="defaultActiveKey"
            :info="info"
            :tab-layout="tabLayout" />
    </page-layout>
</template>

<script>
import {
    Descriptions as ADescriptions,
    Button as AButton,
    Avatar as AAvatar
} from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'
import PagePanel from '@components/PagePanel'
import TabsPanel from '@components/TabsPanel'
import ContainerPanel from '@components/ContainerPanel'
import getEnterpriseInfoService from '@service/enterprise/getEnterpriseInfoService'


export default {
    name: 'EnterpriseInfo',
    components: {
        PageLayout,
        PagePanel,
        TabsPanel,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
        ContainerPanel,
        AButton,
        AAvatar,
        BasicInformation: () => import('./BasicInformation'),
        CreditReportInformation: () => import('./CreditReportInformation'),
        FinancialReportInformation: () => import('./FinancialReportInformation'),
        OrderInformation: () => import('./OrderInformation')
    },
    data() {
        return {
            info: {},
            tabsData: [
                {
                    key: 'BasicInformation',
                    tab: '基本信息'
                },
                {
                    key: 'FinancialReportInformation',
                    tab: '财报信息'
                },
                {
                    key: 'CreditReportInformation',
                    tab: '征信报告'
                },
                {
                    key: 'OrderInformation',
                    tab: '订单信息'
                }
            ],
            defaultActiveKey: 'BasicInformation',
            tabLayout: {
                span: 8,
                wrapperSpan: 20,
                labelSpan: 3
            },
            avatar:
        'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png'
        }
    },
    computed: {
        enterpriseId() {
            return this.$route.query.id
        }
    },
    created() {
        this.getInfo()
    },
    methods: {
    /**
     * tab改变
     */
        handleTabsChange(key) {
            this.defaultActiveKey = key
        },
        async getInfo() {
            const { enterpriseId } = this
            try {
                this.loading = true
                this.info = await getEnterpriseInfoService(enterpriseId)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';
.enterprise-info-page {
  &__info {
    margin-top: -24px;
    display: flex;
  }
  &__title {
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: bold;
    color: #0f0f0f;
  }
  &__title-text {
    vertical-align: middle;
    margin-left: 10px;
  }
  &__info-panel {
    flex: 1;
  }
  &__info-status {
    width: 250px;
    text-align: right;
    padding-right: 5px;
  }
  .enterprise-info-page-panel {
    .ant-card-body {
      padding: $ant-card-body-padding $ant-card-body-padding 0;
    }
    .ant-tabs {
      border-top: 1px dashed $border-color;
    }
  }
}
</style>
