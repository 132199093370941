<template>
    <a-form-model
        ref="ruleForm"
        class="add-edit-category-form-panel"
        layout="horizontal"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
        :model="form"
        :rules="rules">
        <a-form-model-item label="产品分类">
            {{ parentInfo.name }}-{{ categoryInfo.name }}
        </a-form-model-item>
        <a-form-model-item label="险种编号" prop="insuranceType">
            <a-input
                v-model="form.insuranceType"
                :max-length="INPUT_MAX_LENGTH"
            />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel,
    Input as AInput
} from 'ant-design-vue'
import setProductCategoryInsureTypeService
from '@service/commodityManage/productCategory/setProductCategoryInsureTypeService'

import { ERROR_COMMIT_SUCCESS } from '@constant/error'
import { INPUT_MAX_LENGTH } from '@constant/Const'

export default {
    name: 'FormPanel',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput
    },
    props: {
        parentInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        categoryInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        mode: {
            type: String,
            default: 'create'
        }
    },
    data() {
        return {
            INPUT_MAX_LENGTH,
            value: ['china'],
            form: {
                insuranceType: ''
            },
            rules: {
                insuranceType: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入险种编号'
                    }
                ]
            }
        }
    },
    computed: {},
    watch: {
        categoryInfo: {
            handler(val) {
                const { insuranceType } = val
                if (!insuranceType) return
                this.form.insuranceType = insuranceType
            },
            immediate: true
        }
    },
    created() {
    },
    methods: {
        async handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService()
                } else {
                    return false
                }
            })
        },
        async submitService() {
            this.form.id = this.categoryInfo.id
            try {
                this.$emit('loading', true)
                await setProductCategoryInsureTypeService(this.form)
                this.$message.success(ERROR_COMMIT_SUCCESS)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.$emit('loading', false)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
