<!-- 企业详情-征信报告详情 -->
<template>
    <page-layout class="credit-report-info-page">
        <container-panel :no-card-body-padding-top="true">
            <div slot="title">
                <span>单号：{{ riskControlNumber }}</span>
            </div>
            <tabs-panel
                :tabs-data="tabsData"
                :default-active-key="defaultActiveKey"
                @handle-tabs-change="handleTabsChange"
            />
            <div>
                <component :is="defaultActiveKey" class="credit-report-info-page__content" />
            </div>
        </container-panel>
    </page-layout>
</template>

<script>
import {
    Descriptions as ADescriptions,
    Button as AButton,
    Avatar as AAvatar
} from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'
import PagePanel from '@components/PagePanel'
import TabsPanel from '@components/TabsPanel'
import ContainerPanel from '@components/ContainerPanel'

export default {
    name: 'CreditReportInfo',
    components: {
        PageLayout,
        PagePanel,
        TabsPanel,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
        ContainerPanel,
        AButton,
        AAvatar,
        CreditReportDetail: () => import('./CreditReportDetail'),
        MaterialImages: () => import('./MaterialImages')

    },
    data() {
        return {
            tabsData: [
                {
                    key: 'CreditReportDetail',
                    tab: '征信报告'
                }, {
                    key: 'MaterialImages',
                    tab: '资料影像'
                }
            ],
            defaultActiveKey: 'CreditReportDetail'
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    methods: {
    /**
     * tab改变
     */
        handleTabsChange(key) {
            this.defaultActiveKey = key
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';
.credit-report-info-page {
  .ant-card-head {
    background: rgba(0, 0, 0, 0.02);
  }
  &__content {
    // margin-top: 20px;
  }
  .ant-tabs {
    margin-top: 10px;
  }
}
</style>
