<!-- 管理首页-待办 -->
<template>
    <a-spin :spinning="loadding">
        <container-panel class="back-log">
            <div v-if="info" class="back-log__list">
                <div class="back-log__list-item" style="cursor: pointer" @click="handleClick">
                    <back-log-block title="我的待办" icon="my-back-log.png" :value="info.pending" />
                </div>
                <div class="back-log__list-item">
                    <back-log-block title="今日已办" icon="today-done.png" :value="info.finishOfDay" />
                </div>
                <div class="back-log__list-item">
                    <back-log-block title="本月已办" icon="month-done.png" :value="info.finishOfMonth" />
                </div>
                <div class="back-log__list-item">
                    <back-log-block title="全部已办" icon="all-done.png" :value="info.finishAll" />
                </div>
            </div>
        </container-panel>
    </a-spin>
</template>

<script>
import { Spin as ASpin } from 'ant-design-vue'
import BackLogBlock from '../components/BackLogBlock'
import ContainerPanel from '@components/ContainerPanel'
import getPersonTaskService from '@service/home/getPersonTaskService'

export default {
    name: 'BackLog',
    components: {
        BackLogBlock,
        ContainerPanel,
        ASpin
    },
    data() {
        return {
            info: null,
            loadding: false
        }
    },
    created() {
        this.getInfo()
    },
    methods: {
        handleClick() {
            this.$router.push({
                name: 'TaskList'
            })
        },
        async getInfo() {
            try {
                this.loadding = true
                this.info = await getPersonTaskService()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loadding = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.back-log {
  &__list {
    // padding: 0 38px;
    display: flex;
  }

  &__list-item {
    width: 25%;
  }
}
</style>
