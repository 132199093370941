<!-- 财报信息 -->
<template>
    <container-panel :no-card-body-padding-top="true">
        <template slot="title">财报信息</template>
        <financial-report-information :tab-layout="tabLayout" />
    </container-panel>
</template>
<script >
import {} from 'ant-design-vue'
import ContainerPanel from '@components/ContainerPanel'
import FinancialReportInformation from '@weights/EnterpriseInfo/EnterpriseInfoPanel/FinancialReportInformation'

import {} from '@constant/authEnum/enterprise'

export default {
    name: 'FinancialReportInformationPagePanel',
    components: {
        ContainerPanel,
        FinancialReportInformation
    },
    props: {
        tabLayout: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {}
    },
    computed: {},
    mounted() {
    },
    methods: {}
}
</script>
<style lang="scss">
</style>
