<template>
    <search-panel
        :model="form"
        layout="vertical"
        class="enterprise-list-search-panel"
        @search="handleSearch"
        @refresh="handleRefresh"
    >
        <form-col :span="6" label="企业名称" prop="name">
            <a-input
                v-model="form.name"
                slot="formElements"
                placeholder="请输入企业名称" />
        </form-col>
    </search-panel>
</template>

<script>
import { Input as AInput } from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'

import { filterParams } from '@utils/search.js'

export default {
    name: 'EnterpriseListSearchpanel',
    components: {
        AInput,
        SearchPanel,
        FormCol
    },
    data() {
        return {
            form: {
                name: ''
            }
        }
    },
    methods: {
        handleSearch() {
            const { form } = this
            let { ...params } = form
            this.$emit('search', filterParams(params))
        },
        handleRefresh() {
            const { form } = this
            let { ...params } = form
            this.$emit('refresh', filterParams(params))
        }
    }
}
</script>

<style lang="scss">
</style>
