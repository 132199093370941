<!-- 系统首页-保前状态概览 -->
<template>
    <a-spin :spinning="loadding">
        <container-panel class="confirmed-before-overview-panel" :no-card-body-padding-top="true">
            <div slot="title">保前订单状态概览</div>
            <div slot="button" style="display: flex">
                <radio-group-panel
                    :radio-data="radioData"
                    :default-active-key="defaultActiveKey"
                    @change="radioGroupChange"
                />
                <submit-time-section
                    v-show="defaultActiveKey==='4'"
                    v-model="sectionTime"
                    slot="formElements"
                    style="width: 200px"
                    @time="handleTime" />
            </div>
            <div class="confirmed-before-overview-panel__content">
                <div
                    v-for="item in dataList"
                    :key="item.code"
                    class="confirmed-before-overview-panel__item">
                    <overview-amount :title="item.description" :amount="item.number" />
                </div>
            </div>
        </container-panel>
    </a-spin>
</template>

<script>
import { Spin as ASpin } from 'ant-design-vue'
import ContainerPanel from '@components/ContainerPanel'
import RadioGroupPanel from '@components/RadioGroupPanel'
import OverviewAmount from '../components/OverviewAmount'
import getAllTaskService from '@service/home/getAllTaskService'

export default {
    name: 'ConfirmedBeforeOverview',
    components: {
        ASpin,
        ContainerPanel,
        RadioGroupPanel,
        OverviewAmount,
        SubmitTimeSection: () => import('@weights/SubmitTimeSection')
    },
    data() {
        return {
            radioData: [
                {
                    label: '今日',
                    key: '1'
                },
                {
                    label: '本周',
                    key: '2'
                },
                {
                    label: '本月',
                    key: '3'
                },
                {
                    label: '自定义时间段',
                    key: '4'
                }
            ],
            defaultActiveKey: '1',
            customTime: '', // 自定义时间,
            start: '',
            end: '',
            sectionTime: [],
            loadding: false,
            dataList: []
        }
    },
    created() {
        this.getList({
            dateType: this.defaultActiveKey
        })
    },
    methods: {
        handleTime(val) {
            const start = `${val[0]}`
            const end = `${val[1]}`
            this.getList({
                dateType: 4,
                start,
                end
            })
        },
        radioGroupChange(e) {
            const { value } = e.target || {}
            this.defaultActiveKey = value
            if (value !== '4') {
                this.getList({
                    dateType: value
                })
            }
        },
        async getList(params) {
            try {
                this.loadding = true
                this.dataList = await getAllTaskService(params)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loadding = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.confirmed-before-overview-panel {
  .radio-group-panel {
    display: inline-block;
    margin-right: 10px;
  }

  .ant-calendar-picker {
    display: inline-block;
  }

  &__content {
    display: flex;
    border-top: 1px solid #f0f0f0;
    padding-top: 20px;
  }

  &__item {
    width: 16.66%;
    text-align: center;
  }
}
</style>
