<!--  企业详情-征信报告-资料影像 -->
<template>
    <container-panel class="material-images-panel">
        <template slot="title">征信报告</template>
        <template slot="button">
            <custom-button
                v-auth="ENTERPRISE_MANAGE_ENTERPRISE_INFO_DOWNLOAD_MATERIAL_IMAGES_BUTTON"
                text="下载资料"
                @click="handleDown" />
        </template>
        <a-spin
            :spinning="loading">
            <file-material
                :data-list="dataList" />
        </a-spin>
    </container-panel>
</template>

<script>
import { Spin as ASpin } from 'ant-design-vue'
import ContainerPanel from '@components/ContainerPanel'
import CustomButton from '@components/Button'

import { ENTERPRISE_MANAGE_ENTERPRISE_INFO_DOWNLOAD_MATERIAL_IMAGES_BUTTON } from '@constant/authEnum/enterprise'
import downloadCreditReportFileService from '@service/enterprise/downloadCreditReportFileService'
import getCreditReportFileService from '@service/enterprise/getCreditReportFileService'

export default {
    name: 'MaterialImagesPagePanel',
    components: {
        ASpin,
        ContainerPanel,
        CustomButton,
        FileMaterial: () => import('@weights/FileMaterial')
    },
    data() {
        return {
            loading: false,
            dataList: [],
            ENTERPRISE_MANAGE_ENTERPRISE_INFO_DOWNLOAD_MATERIAL_IMAGES_BUTTON
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        async handleDown() {
            const { riskControlNumber } = this
            try {
                await downloadCreditReportFileService({
                    riskControlNumber
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        async getList() {
            const { riskControlNumber } = this
            try {
                this.loading = true
                this.dataList = await getCreditReportFileService({
                    riskControlNumber
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
</style>
