<!-- 待办块 -->
<template>
    <div class="back-log-block">
        <img class="back-log-block__icon" :src="require('@/assets/images/'+icon)" :alt="title">
        <div class="back-log-block__text">
            <p class="back-log-block__title">{{ title }}</p>
            <p class="back-log-block__amount">{{ value }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BackLogBlock',
    props: {
        title: {
            type: String,
            default: '',
            required: true
        },
        value: {
            type: Number,
            default: 0,
            required: true
        },
        icon: {
            type: String,
            default: '',
            required: true
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';
.back-log-block {
  display: flex;
  width: 200px;
  height: 90px;
  background: #fff;
  border-radius: 2px;
  //border: 1px solid #f0f0f0;
  padding: 26px 0;
  margin: 0 auto;
  &__icon {
    width: 36px;
    height: 36px;
    border-radius: 36px;
    margin-left: 39px;
    margin-right: 16px;
  }
  &__text {
    margin-top: -2px;
    position: relative;
    &:before {
      display: inline-block;
      content: '';
      width: 1px;
      height: 43px;
      background: #eeeded;
      position: absolute;
    }
  }
  p {
    margin: 0;
    padding-left: 16px;
  }
  &__title {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666;
    line-height: 18px;
  }
  &__amount {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #0f0f0f;
  }
}
</style>
