<template>
    <page-layout class="order-info-page" :show-loading="true" :loading="loading">
        <head-com
            :risk-order-detail-d-t-o="riskOrderDetailDTO">
            <div slot="headButton">
                <a-button
                    v-if="showAuditButton"
                    v-auth="ORDER_MANAGE_ORDER_LIST_AUDIT_ORDER_BUTTON"
                    type="primary"
                    @click="handleAudit"
                >
                    退单审核
                </a-button>
                <a-button
                    v-if="showReSignButton"
                    v-auth="ORDER_MANAGE_ORDER_INFO_AUDIT_RESIGN_BUTTON"
                    type="primary"
                    @click="handleReSign"
                >
                    重签审核
                </a-button>
            </div>
            <tabs-panel
                :tabs-data="tabsData"
                :default-active-key="defaultActiveKey"
                @handle-tabs-change="handleTabsChange"
            />
        </head-com>
        <component
            :is="defaultActiveKey"
            :task-info="taskInfo"
            @risk="handleRisk" />
        <!-- 退单审核 -->
        <audit-order-modal
            v-bind="auditOrderModal"
            @close="auditOrderModal.visible=false"
            @success="handleAuditModalSuccess" />
        <!-- 报告重签审核 -->
        <re-sign-modal
            v-bind="reSignModal"
            @close="reSignModal.visible=false"
            @success="handleReSignModalSuccess"
        />
    </page-layout>
</template>

<script>
import {
    Descriptions as ADescriptions,
    Button as AButton
} from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'
import PagePanel from '@components/PagePanel'
import TabsPanel from '@components/TabsPanel'
import getOrderInfoService from '@service/order/getOrderInfoService'
import enumOperate from '@mixins/enumOperate'
import {
    ORDER_BUSINESS_STATE_INIT_STATE_TYPE,
    ORDER_STATE_TYPE_ENUM_LIST,
    LOOK_THREE_TABLE_LIST,
    LOOK_FOUR_TABLE_LIST,
    LOOK_FIVE_TABLE_LIST,
    ORDER_BUSINESS_STATE_RESIGN_STATE_TYPE,
    REPORT_RESIGN_AUTH_BUTTON,
    REVOCATION_STATE_TYPE,
    ORDER_BACK_AUTH_BUTTON
} from '@constant/enum'
import { ORDER_MANAGE_ORDER_INFO_AUDIT_RESIGN_BUTTON, ORDER_MANAGE_ORDER_LIST_AUDIT_ORDER_BUTTON } from '@constant/authEnum/order'
import { tabsTwoData, tabsThreeData, tabsFourData, tabsFiveData } from './tabsDataList'
import { isRiskCategoryBid } from '@constant/Const'

export default {
    name: 'OrderInfo',
    components: {
        HeadCom: () => import('@weights/TaskAndOrderCom/HeadCom'),
        PageLayout,
        PagePanel,
        TabsPanel,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
        AButton,
        OrderDetailInfoPanel: () => import('@weights/TaskAndOrderTabPanel/OrderDetailInfoPanel'),
        RiskControlInfoPanel: () => import('@weights/TaskAndOrderTabPanel/RiskControlInfoPanel'),
        RiskAccessInfoPanel: () => import('@weights/TaskAndOrderTabPanel/RiskAccessInfoPanel'),
        RelevanceInfoPanel: () => import('@weights/TaskAndOrderTabPanel/RelevanceInfoPanel'),
        OperationLogInfoPanel: () => import('@weights/TaskAndOrderTabPanel/OperationLogInfoPanel'),
        InsureInfoPanel: () => import('@weights/TaskAndOrderTabPanel/InsureInfoPanel'),
        AuditOrderModal: () => import('../OrderList/AuditOrderModal'),
        ReSignModal: () => import('../OrderList/ReSignModal')
    },
    mixins: [enumOperate],
    data() {
        return {
            ORDER_MANAGE_ORDER_LIST_AUDIT_ORDER_BUTTON,
            ORDER_BUSINESS_STATE_INIT_STATE_TYPE,
            ORDER_STATE_TYPE_ENUM_LIST,
            riskOrderDetailDTO: {},
            defaultActiveKey: 'OrderDetailInfoPanel',
            loading: false,
            taskInfo: {},
            auditOrderModal: {
                visible: false,
                riskControlNumber: ''
            },
            reSignModal: {
                visible: false,
                riskControlNumber: ''
            },
            ORDER_MANAGE_ORDER_INFO_AUDIT_RESIGN_BUTTON
        }
    },
    computed: {
        showAuditButton() {
            const { taskInfo } = this
            const revocationState = taskInfo?.riskOrderDetailDTO?.revocationState
            const buttonPermissionPro = taskInfo?.buttonPermissionPro || {}
            return revocationState === REVOCATION_STATE_TYPE && buttonPermissionPro[ORDER_BACK_AUTH_BUTTON] === true
        },
        // 报告重签审核按钮
        showReSignButton() {
            const { taskInfo } = this
            const businessState = taskInfo?.riskOrderDetailDTO?.businessState
            const buttonPermissionPro = taskInfo?.buttonPermissionPro || {}
            return buttonPermissionPro[REPORT_RESIGN_AUTH_BUTTON] === true && businessState === ORDER_BUSINESS_STATE_RESIGN_STATE_TYPE
        },
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        },
        /**
         * 动态tab
         * */
        tabsData() {
            const { riskOrderDetailDTO } = this
            const { businessState, state, riskCategoryId } = riskOrderDetailDTO
            let tabsData = []

            if (businessState === ORDER_BUSINESS_STATE_INIT_STATE_TYPE) { // 业务状态为1
                if (LOOK_THREE_TABLE_LIST.find(item => item === state)) {
                    tabsData = tabsThreeData
                } else if (LOOK_FOUR_TABLE_LIST.find(item => item === state)) {
                    tabsData = tabsFourData
                } else if (LOOK_FIVE_TABLE_LIST.find(item => item === state)) {
                    tabsData = tabsFiveData
                } else {
                    tabsData = tabsTwoData
                }
            } else { // 不为1
                tabsData = tabsFiveData
            }
            // 投标商品
            if (isRiskCategoryBid(riskCategoryId)) {
                tabsData = tabsFiveData.filter(item => item.bidType !== false)
            }
            // 承保信息是否显示
            if (!this.taskInfo.haveInsuranceInfo) {
                tabsData = tabsData.filter(item => item.key !== 'InsureInfoPanel')
            }
            return tabsData
        }
    },
    watch: {
        $route: {
            handler() {
                this.getInfo()
            },
            immediate: true
        }
    },
    methods: {
        handleAudit() {
            this.auditOrderModal = {
                visible: true,
                riskControlNumber: this.riskControlNumber
            }
        },
        /**
         * 点击重签审核
         * */
        handleReSign() {
            this.reSignModal = {
                visible: true,
                riskControlNumber: this.riskControlNumber
            }
        },
        handleAuditModalSuccess() {
            this.auditOrderModal.visible = false
            this.getInfo()
        },
        // 重签成功
        handleReSignModalSuccess() {
            this.reSignModal.visible = false
            this.getInfo()
        },
        handleRisk(val) {
            const p = JSON.parse(JSON.stringify(this.$route.query))
            this.$router.push({
                name: 'OrderListInfo',
                query: Object.assign(p, {
                    riskControlNumber: val
                })
            })
        },
        /**
         * tab改变
         */
        handleTabsChange(key) {
            this.defaultActiveKey = key
        },
        /**
         * 获取任务详情
         * */
        async getInfo() {
            try {
                this.loading = true
                const { riskControlNumber } = this
                this.taskInfo = await getOrderInfoService({
                    riskControlNumber
                })
                const { riskOrderDetailDTO, associatedOrderList } = this.taskInfo
                this.riskOrderDetailDTO = riskOrderDetailDTO || {}
                this.associatedOrderList = associatedOrderList || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.order-info-page {
    &__info {
        margin-top: -24px;
        display: flex;
    }

    &__title {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: bold;
        color: #0f0f0f;
    }

    &__info-panel {
        flex: 1;
    }

    &__info-status {
        width: 250px;
        text-align: right;
        padding-right: 5px;
    }
}
</style>
