// 第一级
export const firstColumns = [
    {
        title: '序号',
        dataIndex: 'id',
        align: 'left',
        scopedSlots: {
            customRender: 'id'
        },
        width: 100,
        key: 'id'
    },
    {
        title: '分类名称',
        dataIndex: 'name',
        align: 'left',
        width: 260,
        key: 'name'
    },
    {
        title: '分类级别',
        dataIndex: 'level',
        align: 'left',
        width: 120,
        key: 'level'
    },
    {
        title: '是否启用',
        dataIndex: 'state',
        align: 'left',
        width: 200,
        scopedSlots: {
            customRender: 'state'
        },
        key: 'state'
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        width: 460,
        scopedSlots: {
            customRender: 'action'
        }
    }
]
export const childColumns = [
    {
        title: '序号',
        dataIndex: 'id',
        align: 'left',
        width: 100,
        scopedSlots: {
            customRender: 'id'
        },
        key: 'id'
    },
    {
        title: '分类名称',
        dataIndex: 'name',
        align: 'left',
        width: 260,
        key: 'name'
    },
    {
        title: '分类级别',
        dataIndex: 'level',
        align: 'left',
        width: 120,
        key: 'level'
    },
    {
        title: '上级分类',
        dataIndex: 'parentName',
        align: 'left',
        width: 200,
        key: 'parentName'
    },
    {
        title: '是否启用',
        dataIndex: 'state',
        align: 'left',
        width: 200,
        scopedSlots: {
            customRender: 'state'
        },
        key: 'state'
    },
    {
        title: '操作',
        key: 'action',
        width: 460,
        align: 'left',
        scopedSlots: {
            customRender: 'action'
        }
    }
]
