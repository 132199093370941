var render = function render(){var _vm=this,_c=_vm._self._c;return _c('list-page-layout',{staticClass:"message-center-page"},[_c('search-panel',{attrs:{"slot":"header"},on:{"search":_vm.handleSearch,"refresh":_vm.handleRefresh},slot:"header"}),_c('container-panel',[_c('custom-button',{directives:[{name:"auth",rawName:"v-auth",value:(_vm.HOME_PAGE_MESSAGE_CENTER_ALL_READED_BUTTON),expression:"HOME_PAGE_MESSAGE_CENTER_ALL_READED_BUTTON"}],attrs:{"slot":"button","text":"一键已读"},on:{"click":_vm.handleAllReaded},slot:"button"}),_c('template',{slot:"content"},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataList,"pagination":_vm.pagination,"loading":_vm.loading,"scroll":_vm.scrollConfig,"row-key":"id","row-selection":{
                    selectedRowKeys: _vm.selectedRowKeys,
                    onChange: _vm.onSelectChange ,
                    getCheckboxProps: record => ({
                        props: {
                            disabled: _vm.isRead(record.state)
                        },
                    }),}},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"content",fn:function(text,action){return [_c('span',{class:{noAllowed:_vm.isRead(action.state),pointer:!_vm.isRead(action.state)},on:{"click":function($event){return _vm.handleContent(action)}}},[_vm._v(_vm._s(text))])]}},{key:"action",fn:function(text,action){return [_c('a-button',{directives:[{name:"auth",rawName:"v-auth",value:(_vm.HOME_PAGE_MESSAGE_CENTER_SET_READED_BUTTON),expression:"HOME_PAGE_MESSAGE_CENTER_SET_READED_BUTTON"}],attrs:{"type":"link","disabled":action.state===_vm.IS_READE},on:{"click":function($event){return _vm.handleReaded(action.id)}}},[_vm._v(" 设为已读 ")])]}}])})],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }