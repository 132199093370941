<template>
    <list-page-layout>
        <container-panel>
            <template slot="title">
                产品分类列表 {{ parentInfo.name }}
            </template>
            <template slot="button">
                <a-button
                    v-show="steps.length"
                    @click="handleBack">
                    返回上级
                </a-button>
                <a-button v-auth="COMMODITY_MANAGE_COMMODITY_CATEGORY_ADD_CATEGORY_BUTTON"
                          type="primary"
                          @click="handleAddCategory">
                    添加分类
                </a-button>
            </template>
            <template slot="content" slot-scope="">
                <a-table :columns="columns"
                         :data-source="dataList"
                         :pagination="pagination"
                         :loading="loading"
                         row-key="id"
                         :scroll="scrollConfig"
                         @change="handleTableChange">
                    <template slot="id" slot-scope="text,record,index">
                        {{ +index+1 }}
                    </template>
                    <template slot="state" slot-scope="text,record">
                        <a-switch :checked="Boolean(record.state)" @change="value => handleChangeSwitch(value, record)" />
                    </template>
                    <template slot="action" slot-scope="text,action">
                        <a-button
                            v-auth="COMMODITY_MANAGE_COMMODITY_CATEGORY_LOOK_CATEGORY_BUTTON"
                            type="link"
                            @click="handleLookInfo(action)">
                            查看下级
                        </a-button>
                        <a-button
                            v-auth="COMMODITY_MANAGE_COMMODITY_CATEGORY_EDIT_CATEGORY_BUTTON"
                            type="link"
                            :disabled="action.state===1"
                            @click="handleEdit(action)">
                            编辑
                        </a-button>
                        <a-button
                            v-auth="COMMODITY_MANAGE_COMMODITY_CATEGORY_DELETE_CATEGORY_BUTTON"
                            type="link"
                            :disabled="action.state===1"
                            @click="handleDelete(action)">
                            删除
                        </a-button>
                        <template v-if="action.level>1">
                            <a-button
                                v-auth="COMMODITY_MANAGE_COMMODITY_CATEGORY_PARAMS_SETTING_BUTTON"
                                type="link"
                                @click="handleParamsSetting(action)">
                                保前参数
                            </a-button>
                            <a-button
                                v-auth="COMMODITY_MANAGE_COMMODITY_CATEGORY_SET_INSURE_TYPE_BUTTON"
                                type="link"
                                @click="handleInsureType(action)">
                                险种编号
                            </a-button>
                        </template>
                    </template>
                </a-table>
            </template>
        </container-panel>

        <add-or-edit-category-modal
            :mode="mode"
            :visible="visible"
            :category-info="categoryInfo"
            :parent-info="parentInfo"
            @cancel="handleCancel('visible')"
            @success="handleSuccess('visible')" />
        <!-- 保前参数 -->
        <params-setting-modal
            :visible="paramsVisible"
            :category-info="categoryInfo"
            :parent-info="parentInfo"
            @cancel="handleCancel('paramsVisible')"
            @success="handleCancel('paramsVisible')" />
        <!-- 设置险种编号 -->
        <set-insure-type-modal
            :visible="insureVisible"
            :category-info="categoryInfo"
            :parent-info="parentInfo"
            @cancel="handleCancel('insureVisible')"
            @success="handleCancel('insureVisible')" />
    </list-page-layout>
</template>

<script>
import { Button as AButton, Table as ATable, Switch as ASwitch } from 'ant-design-vue'
import ContainerPanel from '@components/ContainerPanel'
import ListPageLayout from '@layout/ListPageLayout'
import {
    COMMODITY_MANAGE_COMMODITY_CATEGORY_ADD_CATEGORY_BUTTON,
    COMMODITY_MANAGE_COMMODITY_CATEGORY_EDIT_CATEGORY_BUTTON,
    COMMODITY_MANAGE_COMMODITY_CATEGORY_LOOK_CATEGORY_BUTTON,
    COMMODITY_MANAGE_COMMODITY_CATEGORY_DELETE_CATEGORY_BUTTON,
    COMMODITY_MANAGE_COMMODITY_CATEGORY_PARAMS_SETTING_BUTTON,
    COMMODITY_MANAGE_COMMODITY_CATEGORY_SET_INSURE_TYPE_BUTTON
} from '@constant/authEnum/commodity'
import { firstColumns, childColumns } from './columns'
import { scrollConfig } from '@constant/Const'
import tableShowTotal from '@mixins/tableShowTotal'
import { DEFAULT_PAGES_SIZE } from '@config'
import { ERROR_COMMIT_OPEN, ERROR_COMMIT_CLOSE, ERROR_COMMIT_DELETE } from '@constant/error'
import { filterParams } from '@utils/search'

import getProductCategoryListService from '@service/commodityManage/productCategory/getProductCategoryListService'
import deleteProductCategoryService from '@service/commodityManage/productCategory/deleteProductCategoryService'
import openOrCloseProductCategoryService
from '@service/commodityManage/productCategory/openOrCloseCategorySwitchService'

export default {
    name: 'ProductCategory',
    components: {
        ListPageLayout,
        ContainerPanel,
        AButton,
        ASwitch,
        ATable,
        AddOrEditCategoryModal: () => import('./AddOrEditCategoryModal'),
        ParamsSettingModal: () => import('./ParamsSettingModal'),
        SetInsureTypeModal: () => import('./SetInsureTypeModal')
    },
    mixins: [tableShowTotal],
    data() {
        return {
            scrollConfig,
            COMMODITY_MANAGE_COMMODITY_CATEGORY_ADD_CATEGORY_BUTTON,
            COMMODITY_MANAGE_COMMODITY_CATEGORY_EDIT_CATEGORY_BUTTON,
            COMMODITY_MANAGE_COMMODITY_CATEGORY_DELETE_CATEGORY_BUTTON,
            COMMODITY_MANAGE_COMMODITY_CATEGORY_LOOK_CATEGORY_BUTTON,
            COMMODITY_MANAGE_COMMODITY_CATEGORY_PARAMS_SETTING_BUTTON,
            COMMODITY_MANAGE_COMMODITY_CATEGORY_SET_INSURE_TYPE_BUTTON,
            params: {},
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            mode: '',
            visible: false,
            categoryInfo: {},
            parentInfo: {},
            paramsVisible: false,
            insureVisible: false,
            steps: []
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        parentId() {
            return this.parentInfo.id || ''
        },
        columns() {
            const { parentInfo } = this
            if (parentInfo && parentInfo.name) {
                return childColumns
            } else {
                return firstColumns
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleBack() {
            const { steps } = this
            const len = steps.length
            if (len === 1) {
                this.parentInfo = {}
            } else {
                this.parentInfo = steps[1]
            }
            this.getList()
            this.steps.shift()
        },
        async handleChangeSwitch(value, record) {
            let param = {
                enable: +value,
                id: record.id
            }
            try {
                await openOrCloseProductCategoryService(param)
                let message = value ? ERROR_COMMIT_OPEN : ERROR_COMMIT_CLOSE
                this.$message.success(message)
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }

        },
        handleAddCategory() {
            this.mode = 'create'
            this.visible = true
            this.categoryInfo = {}
        },
        handleEdit(action) {
            this.mode = 'edit'
            this.visible = true
            this.categoryInfo = action
        },
        handleSuccess(visible) {
            this[visible] = false
            this.getList()
        },
        handleCancel(visible) {
            this[visible] = false
        },
        handleLookInfo(action) {
            this.steps.unshift(action)

            this.parentInfo = action
            let param = {
                parentId: action.id
            }
            this.getList(param)
        },
        handleDelete(action) {
            this.$confirm({
                title: '删除当前分类，将删除当前分类及当前分类的下级分类，确认删除吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.deleteCategory(action)
                },
                onCancel() {
                }
            })
        },
        handleParamsSetting(action) {
            this.categoryInfo = action
            this.paramsVisible = true
        },
        handleInsureType(action) {
            this.categoryInfo = action
            this.insureVisible = true
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        async deleteCategory(action) {
            try {
                let { id } = action
                await deleteProductCategoryService({
                    id
                })
                this.$message.success(ERROR_COMMIT_DELETE)
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        // parentObj 可查子分类列表 不传查一级
        async getList() {
            try {
                this.loading = true
                const { current, limit, parentId } = this
                let param = {
                    current,
                    size: limit,
                    parentId
                }
                const result = await getProductCategoryListService(filterParams(param))
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
