<template>
    <a-form-model
        ref="ruleForm"
        class="add-edit-category-form-panel"
        layout="horizontal"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
        :model="form"
        :rules="rules">
        <a-form-model-item v-if="parentInfo.name" label="上级分类">
            {{ parentInfo.name }}
        </a-form-model-item>
        <a-form-model-item label="分类名称" prop="name">
            <a-input v-model="form.name"  :max-length="INPUT_MAX_LENGTH" placeholder="请输入分类名称" />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel,
    input as AInput
} from 'ant-design-vue'

import { ERROR_COMMIT_SUCCESS, ERROR_COMMIT_EDIT } from '@constant/error'
import addProductCategoryService from '@service/commodityManage/productCategory/addProductCategoryService'
import editProductCategoryService from '@service/commodityManage/productCategory/editProductCategoryService'
import { INPUT_MAX_LENGTH } from '@constant/Const'
export default {
    name: 'FormPanel',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput
    },
    props: {
        parentInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        categoryInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        mode: {
            type: String,
            default: 'create'
        }
    },
    data() {
        return {
            INPUT_MAX_LENGTH,
            form: {
                name: ''
            },
            rules: {
                name: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入分类'
                    }
                ]
            }
        }
    },
    computed: {
        parentId() {
            return this.parentInfo.id || ''
        },
        isAdd() {
            return this.mode === 'create'
        },
        commitFn() {
            const { isAdd } = this
            return isAdd ? addProductCategoryService : editProductCategoryService
        },
        commitMsg() {
            const { isAdd } = this
            return isAdd ? ERROR_COMMIT_SUCCESS : ERROR_COMMIT_EDIT
        }

    },
    watch: {
        mode: {
            handler(value) {
                if (value === 'edit') {
                    this.form.name = this.categoryInfo.name
                }
            },
            immediate: true
        }
    },
    methods: {
        handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService()
                } else {
                    return false
                }
            })
        },
        async submitService() {
            try {
                const { id } = this.categoryInfo
                const { commitFn, commitMsg } = this
                if (id) {
                    this.form.id = id
                } else {
                    this.form.parentId = this.parentId
                }
                await commitFn(this.form)
                this.$message.success(commitMsg)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.$emit('submit-finally')
            }
        }

    }
}
</script>

<style lang='scss'>

</style>
