<!-- 基本信息 -->
<template>
    <container-panel class="enterprise-basic-information-page-panel" :no-card-body-padding-top="true">
        <div slot="title">基本信息</div>
        <basic-information-panel :info="info" />
    </container-panel>
</template>

<script>
import ContainerPanel from '@components/ContainerPanel'
import BasicInformationPanel from '@weights/EnterpriseInfo/EnterpriseInfoPanel/BasicInformation/BasicInformationPanel'

export default {
    name: 'EnterpriseBasicInformationPagePanel',
    components: {
        ContainerPanel,
        BasicInformationPanel
    },
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {},
    methods: {}
}
</script>

<style lang="scss">
.enterprise-basic-information-page-panel {
  // margin-top: -20px;
}
</style>
