<template>
    <custom-modal
        class="risk-control-audit-modal"
        title="订单审核"
        :visible="visible"
        @close="handleClose">
        <template slot="customFooter">
            <a-button
                typy="primary"
                :loading="rejectLoading"
                @click="handleSubmit(false,'rejectLoading')">
                驳回
            </a-button>
            <a-button
                type="primary"
                :loading="submitLoading"
                @click="handleSubmit(true,'submitLoading')">
                提交
            </a-button>
        </template>
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :label-col="{ span: 6}"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="{
                remark: [
                    {required: true, trigger: 'blur', message: '请输入备注'},]
            }">
            <a-form-model-item label="审核意见:" prop="remark">
                <a-input
                    v-model="form.remark"
                    type="textarea"
                    :rows="6"
                    :max-length="INPUT_MAX_LENGTH"
                    placeholder="请输入审核意见" />
            </a-form-model-item>
        </a-form-model>
    </custom-modal>
</template>

<script>
import { Button as AButton, FormModel as AFormModel, Input as AInput } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'

import { INPUT_MAX_LENGTH } from '@constant/Const'

import chargebackAuditService from '@service/riskControlProcurement/chargebackAuditService'
import { ERROR_COMMIT_SUCCESS } from '@constant/error'

export default {
    name: 'AuditOrderModal',
    components: {
        AButton,
        CustomModal,
        AFormModel,
        AInput,
        AFormModelItem: AFormModel.Item
    },
    props: {
        riskControlNumber: {
            type: String,
            default: ''
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            submitLoading: false, // 提交loading
            rejectLoading: false, // 驳回loading
            INPUT_MAX_LENGTH,
            form: {
                remark: ''
            }
        }
    },
    watch: {
        visible() {
            this.form.remark = ''
        }
    },
    methods: {
        handleClose() {
            this.$emit('close')
        },
        // 提交/驳回
        handleSubmit(...arg) {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService(...arg)
                }
            })
        },
        async submitService(agree, loadingName) {
            const { form, riskControlNumber, $message, $captureException } = this
            try {
                this[loadingName] = true
                const params = {
                    agree,
                    ...form,
                    riskControlNumber
                }
                await chargebackAuditService(params)
                $message.success(ERROR_COMMIT_SUCCESS)
                this.$emit('success')
            } catch (e) {
                $message.error(e.message)
                $captureException(e.message)
            } finally {
                this[loadingName] = false
            }
        }
    }
}
</script>

<style lang='scss'>
.risk-control-audit-modal {
  .ant-btn {
    width: 94px;
    height: 32px;
    text-align: center;
  }
}

</style>
