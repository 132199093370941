var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-page"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"login-page__login-box"},[_c('div',{staticClass:"login-page__login-box-left"}),_c('div',{staticClass:"login-page__login-box-right"},[_c('div',{staticClass:"login-page__header"},[_c('h1',{staticClass:"login-page__title"},[_vm._v(_vm._s(_vm.systemName))]),_c('h1',{staticClass:"login-page__sub-title"},[_vm._v("用户登录")])]),_c('div',{staticClass:"login-page__content"},[_c('a-form',{ref:"form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[(_vm.errorMessage)?_c('a-alert',{staticClass:"login-page__error-box",attrs:{"type":"error","show-icon":"","message":_vm.errorMessage}}):_vm._e(),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['loginName',{
                                    rules:[{
                                        required:true,
                                        message:'请输入账号'
                                    }],
                                    validateTrigger:'blur'
                                }]),expression:"['loginName',{\n                                    rules:[{\n                                        required:true,\n                                        message:'请输入账号'\n                                    }],\n                                    validateTrigger:'blur'\n                                }]"}],attrs:{"size":"large","placeholder":"请输入账号"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password',{
                                    rules:[{
                                        required:true,
                                        message:'请输入密码'
                                    }],
                                    validateTrigger:'blur'
                                }]),expression:"['password',{\n                                    rules:[{\n                                        required:true,\n                                        message:'请输入密码'\n                                    }],\n                                    validateTrigger:'blur'\n                                }]"}],attrs:{"size":"large","placeholder":"请输入密码"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-page__submit-button",attrs:{"size":"large","type":"primary","html-type":"submit","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v(" 登录 ")])],1)],1)],1)])])]),_c('div',{staticClass:"login-page__footer"},[_c('img',{attrs:{"src":"https://www.cngongbao.com/res/_cache/auto/17/1743.png","alt":"","width":"20px","height":"20px"}}),_vm._v(" 浙公网安备 "),_c('a',{staticStyle:{"margin-right":"5px"},attrs:{"href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802009372","target":"_blank"}},[_vm._v("33010802009372号")]),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("浙ICP备18032966号-3")]),_vm._v(" 工保科技（浙江）有限公司版权所有 © 2014~"+_vm._s(_vm.currentYear)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }