<!-- 保前订单状态概览--块 -->
<template>
    <div class="overview-amount">
        <p class="overview-amount__quantity">{{ amount }}</p>
        <p class="overview-amount__title">{{ title }}</p>
    </div>
</template>

<script>
export default {
    name: 'OverviewAmount',
    props: {
        title: {
            type: String,
            default: '',
            required: true
        },
        amount: {
            type: Number,
            default: 0,
            required: true
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';
.overview-amount {
  display: inline-block;
  p {
    padding: 0;
    margin-bottom: 0;
    text-align: center;
  }
  &__quantity {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333;
    line-height: 33px;
  }
  &__title {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666;
    margin-top: 6px;
    line-height: 18px;
  }
}
</style>
