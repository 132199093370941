<!-- 订单信息 -->
<template>
    <container-panel :no-card-body-padding-top="true">
        <template slot="title">订单信息</template>
        <order-information  :tab-layout="tabLayout" />
    </container-panel>
</template>
<script >
import ContainerPanel from '@components/ContainerPanel'
import OrderInformation from '@weights/EnterpriseInfo/EnterpriseInfoPanel/OrderInformation'

export default {
    name: 'OrderInformationPagePanel',
    components: {
        ContainerPanel,
        OrderInformation
    },
    props: {
        tabLayout: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {}
    },
    computed: {},
    mounted() {
    },
    methods: {}
}
</script>
<style lang="scss">
</style>
