<template>
    <div class="login-page">
        <div class="container">
            <div class="login-page__login-box">
                <div class="login-page__login-box-left" />
                <div class="login-page__login-box-right">
                    <div class="login-page__header">
                        <!--        <img class="login-page__logo" src="~@assets/images/logo.png"/> -->
                        <h1 class="login-page__title">{{ systemName }}</h1>
                        <h1 class="login-page__sub-title">用户登录</h1>
                    </div>
                    <div class="login-page__content">
                        <a-form ref="form" :form="form" @submit="handleSubmit">
                            <a-alert
                                v-if="errorMessage"
                                class="login-page__error-box"
                                type="error"
                                show-icon
                                :message="errorMessage"
                            />

                            <a-form-item>
                                <a-input
                                    v-decorator="['loginName',{
                                        rules:[{
                                            required:true,
                                            message:'请输入账号'
                                        }],
                                        validateTrigger:'blur'
                                    }]"
                                    size="large"
                                    placeholder="请输入账号"
                                >
                                    <a-icon slot="prefix" type="user" />
                                </a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-input-password
                                    v-decorator="['password',{
                                        rules:[{
                                            required:true,
                                            message:'请输入密码'
                                        }],
                                        validateTrigger:'blur'
                                    }]"
                                    size="large"
                                    placeholder="请输入密码"
                                >
                                    <a-icon slot="prefix" type="lock" />
                                </a-input-password>
                            </a-form-item>
                            <a-form-item>
                                <a-button
                                    class="login-page__submit-button"
                                    size="large"
                                    type="primary"
                                    html-type="submit"
                                    :loading="loading"
                                    :disabled="loading"
                                >
                                    登录
                                </a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                </div>
            </div>
        </div>
        <div class="login-page__footer">
            <!--            <p class="copyright">Copyright © 2020. All Rights Reserved.</p> -->
            <img src="https://www.cngongbao.com/res/_cache/auto/17/1743.png" alt="" width="20px" height="20px">
            浙公网安备
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802009372"
               target="_blank"
               style="margin-right: 5px">33010802009372号</a>
            <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备18032966号-3</a>
            工保科技（浙江）有限公司版权所有 © 2014~{{ currentYear }}
        </div>
    </div>
</template>

<script>
import {
    Form as AForm,
    Input as AInput,
    Button as AButton,
    Alert as AAlert,
    Icon as AIcon
} from 'ant-design-vue'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

import { DEFAULT_REDIRECT_PATH } from '@config'

export default {
    name: 'LoginView',
    components: {
        AForm,
        AInput,
        AButton,
        AAlert,
        AInputPassword: AInput.Password,
        AFormItem: AForm.Item,
        AIcon
    },
    data() {
        return {
            form: this.$form.createForm(this),
            loading: false,
            errorMessage: '',
            currentYear: new Date().getFullYear()
        }
    },
    computed: {
        // 跳转路径
        redirect() {
            const route = this.$route
            return route.query && route.query.redirect
                ? route.query.redirect
                : DEFAULT_REDIRECT_PATH
        },
        systemName() {
            return this.$store.getters.systemName || ''
        }
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault()
            const {
                form: { validateFields }
            } = this
            this.loading = true
            validateFields(async (err, values) => {
                if (!err) {
                    try {
                        await this.$store.dispatch('loginByUserName', values)
                        // this.$router.replace({path: redirect})
                        this.$router.replace({
                            path: '/'
                        }, () => {
                        })
                    } catch (e) {
                        this.errorMessage = e.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL
                    } finally {
                        this.loading = false
                    }
                } else {
                    this.loading = false
                }
            })
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.login-page {
    height: 100%;
    background: #f0f2f5 url('~@assets/images/login_bg2.jpg') no-repeat 50%;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #c8c8c8;

    a {
        color: #c8c8c8;
    }

    .container {
        width: 100%;
        position: relative;
    }

    &__login-box {
        width: 950px;
        height: 576px;
        margin: 0 auto;
        display: flex;
    }

    &__login-box-left {
        width: 360px;
        height: 100%;
        background: #f0f2f5 url('~@assets/images/login_bg1.png') no-repeat 50%;
        background-size: 100%;
    }

    &__login-box-right {
        flex: 1;
        background: #fff;
    }

    &__header,
    &__footer {
        text-align: center;
    }

    &__logo {
        margin-bottom: $space-base;
    }

    &__title {
        font-size: 23px;
        font-family: AlibabaPuHuiTi-Light, AlibabaPuHuiTi;
        font-weight: 300;
        color: #999;
        margin-top: 92px;
        margin-bottom: 32px;
    }

    &__content {
        width: 368px;
        margin: 0 auto;
    }

    &__error-box {
        margin-bottom: $padding;
    }

    &__submit-button {
        width: 100%;
        margin-top: 44px;
    }

    &__footer {
        margin: 48px 0 24px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__sub-title {
        font-size: 26px;
        font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
        font-weight: 500;
        color: #333;
        margin-bottom: 37px;
    }
}
</style>
