<template>
    <div class="error-404-page">
        <div class="error-404-page__container">
            <img class="error-404-page__plane" src="~@assets/images/error-plane.svg">
            <img class="error-404-page__error-code" src="~@assets/images/error-404.png">
            <div class="error-404-page__error-message-container">
                <p class="error-404-page__error-message">出错啦！你访问的页面没有找到！</p>
                <p class="error-404-page__error-message">我们努力寻找中…</p>
            </div>
            <div class="error-404-page__button-container">
                <a href="javascript:;"
                   class="error-404-page__return-button"
                   @click="handleReturnButtonClick"
                >返回首页</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ErrorView404',
    methods: {
        handleReturnButtonClick() {
            this.$router.replace({
                path: '/'
            })
        }
    }
}
</script>

<style lang="scss">
@use 'sass:math';
@import '~@/assets/styles/varibles.scss';
.error-404-page {
  width: 100%;
  height: 100%;
  background: url('~@/assets/images/error-bg.svg');
  background-size: cover;
  text-align: center;
  vertical-align: middle;

  &__container {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__error-code {
    width: 290px;
  }

  &__error-message-container {
    text-align: center;
    margin-top: 50px;
  }

  &__error-message {
    font-size: 18px;
    color: #87c0fe;
    margin: 0;
  }

  &__button-container {
    margin-top: 50px;
  }

  &__return-button {
    padding: math.div($space-sm, 2) $space-sm;
    background: #f9fcff;
    color: #1890ff;
    box-shadow: 0 7px 10px -5px #cce3ff;
    border-radius: 12px;
    border: 1px solid #1890ff;
  }

  &__plane {
    position: absolute;
    width: 80px;
    right: 0;
    top: -100px;
  }
}
</style>
