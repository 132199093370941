<!-- 企业详情-财报信息详情 -->
<template>
    <page-layout>
        <container-panel :no-card-body-padding-top="true">
            <div slot="title">
                <span>单号:{{ riskControlNumber }}</span>
            </div>
            <!-- 财报表格数据 -->
            <financial-infos />
        </container-panel>
    </page-layout>
</template>

<script>
import PageLayout from '@layout/PageLayout'
import ContainerPanel from '@components/ContainerPanel'

export default {
    name: 'FinancialReportInfoPage',
    components: {
        PageLayout,
        ContainerPanel,
        FinancialInfos: () => import('@weights/FinancialListPanel/FinancialInfos')
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    }
}
</script>

<style lang="scss">

</style>
