<template>
    <list-page-layout>
        <search-panel slot="header" ref="searchRef" @search="handleSearch" @refresh="handleRefresh" />
        <container-panel>
            <template slot="title">订单列表</template>
            <a-button
                slot="button"
                v-auth="ORDER_MANAGE_ORDER_LIST_EXPORT_ORDER_LIST_BUTTON"
                type="primary"
                :loading="exportLoading"
                @click="handleExport"
            >
                导出
            </a-button>
            <template slot="content">
                <a-table
                    :columns="columns"
                    :data-source="dataList"
                    :pagination="pagination"
                    :loading="loading"
                    :scroll="scrollConfig"
                    row-key="id"
                    @change="handleTableChange">
                    <template slot="proviceCode" slot-scope="text, record">
                        {{ record.proviceCode + record.cityCode }}
                    </template>
                    <template slot="startTime" slot-scope="text,record">
                        {{ text }}至{{ record.endTime }}
                    </template>
                    <template slot="action" slot-scope="action">
                        <a-button
                            v-if="showLookButton(action)"
                            v-auth="ORDER_MANAGE_ORDER_LIST_LOOK_ORDER_LIST_BUTTON"
                            type="link"
                            @click="handleDetail(action)"
                        >
                            查看
                        </a-button>
                        <a-button
                            v-if="showAuditButton(action)"
                            v-auth="ORDER_MANAGE_ORDER_LIST_AUDIT_ORDER_BUTTON"
                            type="link"
                            @click="handleAudit(action)"
                        >
                            退单审核
                        </a-button>
                        <a-button
                            v-if="showReSignButton(action)"
                            v-auth="ORDER_MANAGE_ORDER_LIST_AUDIT_RESIGN_BUTTON"
                            type="link"
                            @click="handleReSign(action)"
                        >
                            重签审核
                        </a-button>
                    </template>
                </a-table>
            </template>
        </container-panel>
        <!-- 退单审核 -->
        <audit-order-modal
            v-bind="auditOrderModal"
            @close="auditOrderModal.visible=false"
            @success="handleAuditModalSuccess" />
        <!-- 报告重签审核 -->
        <re-sign-modal
            v-bind="reSignModal"
            @close="reSignModal.visible=false"
            @success="handleReSignModalSuccess"
        />
    </list-page-layout>
</template>
<script>
import { Button as AButton, Table as ATable } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import SearchPanel from '@weights/TaskAndOrderCom/SearchPanel'
import exportOrderListService from '@service/order/exportOrderListService'
import {
    ORDER_MANAGE_ORDER_LIST_EXPORT_ORDER_LIST_BUTTON,
    ORDER_MANAGE_ORDER_LIST_LOOK_ORDER_LIST_BUTTON,
    ORDER_MANAGE_ORDER_LIST_AUDIT_ORDER_BUTTON,
    ORDER_MANAGE_ORDER_LIST_AUDIT_RESIGN_BUTTON
} from '@constant/authEnum/order'
import { DEFAULT_PAGES_SIZE } from '@config'
import {
    REVOCATION_STATE_TYPE,
    ORDER_BACK_AUTH_BUTTON,
    REPORT_RESIGN_AUTH_BUTTON,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_REPORT_RE_SIGN
} from '@constant/enum'

import tableShowTotal from '@mixins/tableShowTotal'

import getOrderAllListService from '@service/order/getOrderAllListService'
import columns from './columns'
import enumOperate from '@mixins/enumOperate'
import { filterParams } from '@utils/search.js'
import { open } from '@utils'

export default {
    name: 'OrderList',
    components: {
        SearchPanel,
        ListPageLayout,
        ContainerPanel,
        AButton,
        ATable,
        AuditOrderModal: () => import('./AuditOrderModal'),
        ReSignModal: () => import('./ReSignModal')
    },
    mixins: [tableShowTotal, enumOperate],
    data() {
        return {
            ORDER_MANAGE_ORDER_LIST_EXPORT_ORDER_LIST_BUTTON, // 导出
            ORDER_MANAGE_ORDER_LIST_LOOK_ORDER_LIST_BUTTON, // 查看
            ORDER_MANAGE_ORDER_LIST_AUDIT_ORDER_BUTTON,
            ORDER_MANAGE_ORDER_LIST_AUDIT_RESIGN_BUTTON,
            REVOCATION_STATE_TYPE,
            columns,
            dataList: [],
            buttonPermission: {}, // 按钮权限
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            exportLoading: false,
            loading: false,
            visible: false,
            scrollConfig: {
                x: 1100,
                y: 500
            },
            auditOrderModal: {
                visible: false,
                riskControlNumber: ''
            },
            reSignModal: {
                visible: false,
                riskControlNumber: ''
            }
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        /**
         * allowSetting
         * 为1表示未确认过是房开还是非房开项目 不能查看
         * 为0表示确认过是房开还是非房开项目 可以查看
         * */
        showLookButton({ allowSetting }) {
            return allowSetting === 0
        },
        /**
         * 显示条件：
         * 1：订单的 revocationState = 1 表示改单是锁单状态
         * 2：且该登录用户有此权限，buttonPermission存储权限对象， ORDER_BACK_AUTH_BUTTON表示该按钮枚举
         * */
        showAuditButton({ revocationState }) {
            const { buttonPermission } = this
            return revocationState === REVOCATION_STATE_TYPE && buttonPermission[ORDER_BACK_AUTH_BUTTON] === true
        },
        // 报告重签审核按钮
        showReSignButton({ channelState }) {
            const { buttonPermission } = this
            return channelState === RISK_CONTROL_PROCUREMENT_ORDER_STATUS_REPORT_RE_SIGN && buttonPermission[REPORT_RESIGN_AUTH_BUTTON] === true
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        handleRefresh(params) {
            this.handleSearch(params)
        },
        async handleExport() {
            if (!this.dataList.length) {
                this.$message.warning('暂无数据导出')
                return
            }
            this.exportLoading = true
            const params = filterParams(this.$refs.searchRef.params)
            // 兼容报错
            exportOrderListService(params)
                .then(() => {
                    this.exportLoading = false
                })
                .catch(err => {
                    this.exportLoading = false
                    this.$message.error(err)
                    this.$captureException(err)
                })
        },
        handleDetail({ riskControlNumber }) {
            open.call(this, 'OrderListInfo', {
                riskControlNumber
            })
        },
        /**
         * 点击退单审核审核
         * */
        handleAudit({ riskControlNumber }) {
            this.auditOrderModal = {
                visible: true,
                riskControlNumber
            }
        },
        /**
         * 点击重签审核
         * */
        handleReSign({ riskControlNumber }) {
            this.reSignModal = {
                visible: true,
                riskControlNumber
            }
        },
        /**
         * 审核成功
         * */
        handleAuditModalSuccess() {
            this.auditOrderModal.visible = false
            this.getList()
        },
        // 重签成功
        handleReSignModalSuccess() {
            this.reSignModal.visible = false
            this.getList()
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params } = this
                const productId = this.$route.query.id || ''
                const param = {
                    currentPage: current,
                    pageSize: limit,
                    productId,
                    ...params
                }
                const { data, buttonPermission } = await getOrderAllListService(param)
                this.dataList = data.records
                this.current = data.current
                this.total = data.total
                this.buttonPermission = buttonPermission
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss">
</style>
