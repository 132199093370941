<!-- 管理首页-个人设置-基本设置 -->
<template>
    <personal-setting-panel title="基本设置">
        <div class="basic-setting-panel">
            <a-form-model ref="basicForm" :model="userInfo" layout="vertical" :wrapper-col="wrapperCol">
                <a-form-model-item label="用户名" prop="userName">
                    <a-input v-model="userInfo.userName" disabled />
                </a-form-model-item>
                <a-form-model-item label="真实姓名" prop="realName">
                    <a-input v-model="userInfo.realName" disabled />
                </a-form-model-item>
                <a-form-model-item label="头像" prop="avatar">
                    <!--          <a-avatar :src="userInfo.avatar" :size="80" /> -->
                    <img src="~@assets/images/avatar-default2x.png" alt="">
                </a-form-model-item>
            </a-form-model>
        </div>
    </personal-setting-panel>
</template>

<script>
import {
    FormModel as AFormModel,
    Input as AInput
} from 'ant-design-vue'
import PersonalSettingPanel from '../components/PersonalSettingPanel'

export default {
    name: 'BasicSetting',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput,
        PersonalSettingPanel
    },
    data() {
        return {
            labelCol: {
                span: 24
            },
            wrapperCol: {
                span: 8
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    methods: {}
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.basic-setting-panel {
  .ant-form-item {
    margin-bottom: 9px;
    display: block;
    align-items: normal;
  }
}
</style>
