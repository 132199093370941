<template>
    <custom-modal
        class="risk-control-audit-modal"
        title="重签审核"
        :width="1000"
        :visible="visible"
        @close="handleClose">
        <template slot="customFooter">
            <a-button
                typy="primary"
                :loading="rejectLoading"
                @click="handleSubmit(false,'rejectLoading')">
                拒绝
            </a-button>
            <a-button
                type="primary"
                :loading="submitLoading"
                @click="handleSubmit(true,'submitLoading')">
                通过
            </a-button>
        </template>
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :label-col="{ span: 6}"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="{
                remark: [
                    {required: true, trigger: 'blur', message: '请输入备注'},]
            }">
            <a-form-model-item label="报告修改内容:">
                <a-descriptions bordered>
                    <a-descriptions-item :span="3" label="保险金额">{{ info.insuranceAmount }}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="保费">{{ info.premium }}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="保险期限">
                        {{ info.startTime }} ~ {{
                            info.endTime
                        }}
                    </a-descriptions-item>
                    <a-descriptions-item label="风险提示">
                        <span v-for="(item,idx) in riskWarnings" :key="idx">
                            {{ item }} <br>
                        </span>
                    </a-descriptions-item>
                </a-descriptions>
            </a-form-model-item>
            <a-form-model-item label="发起人:">
                {{ info.userName }}
            </a-form-model-item>
            <a-form-model-item label="审核意见:" prop="remark">
                <a-input
                    v-model="form.remark"
                    type="textarea"
                    :rows="6"
                    :max-length="INPUT_MAX_LENGTH"
                    placeholder="请输入审核意见" />
            </a-form-model-item>
        </a-form-model>
    </custom-modal>
</template>

<script>
import {
    Button as AButton,
    FormModel as AFormModel,
    Input as AInput,
    Descriptions as ADescriptions
} from 'ant-design-vue'
import CustomModal from '@components/CustomModal'

import { INPUT_MAX_LENGTH } from '@constant/Const'

import reportReSignAuthService from '@service/order/reportReSignAuthService'
import getReportReSignInfoService from '@service/order/getReportReSignInfoService'
import { ERROR_COMMIT_SUCCESS } from '@constant/error'

export default {
    name: 'AuditOrderModal',
    components: {
        AButton,
        CustomModal,
        AFormModel,
        AInput,
        AFormModelItem: AFormModel.Item,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item
    },
    props: {
        riskControlNumber: {
            type: String,
            default: ''
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            submitLoading: false, // 提交loading
            rejectLoading: false, // 驳回loading
            INPUT_MAX_LENGTH,
            form: {
                remark: ''
            },
            info: {}
        }
    },
    computed: {
        riskWarnings() {
            return this.info.riskWarning?.split('|')
        }
        // this.getInfo()
    },
    watch: {
        visible(val) {
            val && this.getInfo()
            this.form = {}
        }
    },
    methods: {
        handleClose() {
            this.$emit('close')
        },
        // 提交/驳回
        handleSubmit(...arg) {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService(...arg)
                }
            })
        },
        async getInfo() {
            const { riskControlNumber } = this
            try {
                this.info = await getReportReSignInfoService({
                    riskControlNumber
                }) || {}
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        async submitService(agree, loadingName) {
            const { form, riskControlNumber, $message, $captureException } = this
            try {
                this[loadingName] = true
                const params = {
                    agree,
                    ...form,
                    riskControlNumber
                }
                await reportReSignAuthService(params)
                $message.success(ERROR_COMMIT_SUCCESS)
                this.$emit('success')
            } catch (e) {
                $message.error(e.message)
                $captureException(e.message)
            } finally {
                this[loadingName] = false
            }
        }
    }
}
</script>

<style lang='scss'>
.risk-control-audit-modal {
    .ant-btn {
        width: 94px;
        height: 32px;
        text-align: center;
    }

    .ant-descriptions-item-label {
        width: 160px;
    }
}

</style>
