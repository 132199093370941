<!-- 征信报告列表 -->
<template>
    <container-panel :no-card-body-padding-top="true">
        <template slot="title">征信报告</template>
        <credit-report-information :tab-layout="tabLayout" />
    </container-panel>
</template>
<script >
import ContainerPanel from '@components/ContainerPanel'
import CreditReportInformation from '@weights/EnterpriseInfo/EnterpriseInfoPanel/CreditReportInformation'

export default {
    name: 'CreditReportInformationPagePanel',
    components: {
        ContainerPanel,
        CreditReportInformation
    },
    props: {
        tabLayout: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {},
    methods: {}
}
</script>
<style lang="scss">
</style>
