<!-- 管理首页-系统首页 -->
<template>
    <page-layout class="sys-home-page">
        <!-- 待办 -->
        <back-log />
        <!-- 保前状态概览 -->
        <confirmed-before-overview />
    </page-layout>
</template>

<script>
import PageLayout from '@layout/PageLayout'
import BackLog from './BackLog'
import ConfirmedBeforeOverview from './ConfirmedBeforeOverview'

export default {
    name: 'SysHomePage',
    components: {
        BackLog,
        PageLayout,
        ConfirmedBeforeOverview
    }
}
</script>

<style lang="scss">
</style>
